import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// COMPONENTS ------------------------------------------------------------------

import {
    HomepageComponent
} from 'src/app/pages/homepage/homepage.component';

const routes: Routes = [
    { path: 'home', component: HomepageComponent },
    { path: '**', redirectTo: '/home' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabledBlocking' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
