// ANGULAR ---------------------------------------------------------------------

import { 
  Component, 
  Inject, 
  OnInit 
} from '@angular/core';

// Dialog stuff
import {
  // Reference to the dialog object that was created by the calling component
  MatDialogRef, 
  // The data object to be returned to the calling component
  MAT_DIALOG_DATA
} from '@angular/material/dialog';

// THIRD PARTY -----------------------------------------------------------------

// HELPERS ---------------------------------------------------------------------

import {
  GeneralHelperService
} from 'src/app/services/general_helper/general-helper.service';

// INTERFACES ------------------------------------------------------------------

import {
  WindowResize
} from 'src/app/services/resize_helper/resize-helper.interface';

// APIS ------------------------------------------------------------------------

// COMPONENTS ------------------------------------------------------------------

// DATA STRUCTURES -------------------------------------------------------------

import { 
  WindowStates 
} from 'src/app/data_structures/shared/window-states/window-states.ds';

import {
  CustomButtonClickEvent,
  CustomButtonConfig,
  CustomButtonType
} from 'src/app/data_structures/specific/custom-button/custom-button.ds';

import {
  ContactDetailsDialogData, DialogSizeMode
} from 'src/app/data_structures/shared/contact_details/contact_details.ds';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit, WindowResize {

  constructor(public dialogRef: MatDialogRef<ContactDetailsComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ContactDetailsDialogData,
      private generalHelper: GeneralHelperService) { 
    // Prevents the dialog menu from being closed when clicking out
    this.dialogRef.disableClose = true;

    if(data != undefined) {
      this.name = data.name;
      this.email = data.email;
      this.phone = data.phone;
      this.button_init();
      if(this.data.mode == DialogSizeMode.BIG) {
        this.isBig = true;
        console.log("Big mode");
      } else {
        this.isBig = false;
        console.log("Small mode");
      }
    }
  }

  ngOnInit(): void {
  }

  // * PUBLIC METHODS ----------------------------------------------------------

  public window_state_change(newState: WindowStates) {
    switch(newState) {
      case WindowStates.XSMALL:
        // Resize elements to be XSMALL (x < 600)
        console.log("XSMALL");
        this.dialogWidth = '90vw';

        break;
      case WindowStates.SMALL:
        // Resize elements to be SMALL (600 <= x <1024)
        console.log("SMALL");
        this.dialogWidth = '600px';
        break;
      case WindowStates.MEDIUM:
        // Resize elements to be MEDIUM (1024 <= x < 1440)
        console.log("MEDIUM");
        this.dialogWidth = '600px';
        break;
      case WindowStates.LARGE:
        // Resize elements to be LARGE (1440 <= x < 1920)
        console.log("LARGE");
        this.dialogWidth = '600px';
        break;
      case WindowStates.XLARGE:
        // Resize elements to be XLARGE (1920 <= x)
        console.log("XLARGE");
        this.dialogWidth = '600px';
        
        break;
      case WindowStates.SERVER:
        
        break;
      default:
        console.error("Unknown windows state: " + newState);
    }
  }

  public button_handler(type: string) {
    switch(type) {
      case 'github':
        window.open(this.data.githubURL);
        break;
      case 'linkedin':
        window.open(this.data.linkedinURL);
        break;
      case 'stackoverflow':
        window.open(this.data.stackoverflowURL);
        break;
      case 'mathstackexchange':
        window.open(this.data.mathstackexchangeURL);
        break;
      case 'exit':
        this.dialogRef.close();
        break;
      default:
        console.error("Unknown type: " + type);
    }
  }

  // * PUBLIC VARIABLES --------------------------------------------------------

  public name: string;
  public email: string;
  public phone: string;
  public githubBtnConfig: CustomButtonConfig;
  public linkedinBtnConfig: CustomButtonConfig;
  public stackoverflowBtnConfig: CustomButtonConfig;
  public mathstackexchangeBtnConfig: CustomButtonConfig;

  public isBig: boolean = true;

  // ? Display Variables
  public dialogWidth: string = "600px";

  // * PRIVATE METHODS ---------------------------------------------------------

  private button_init() {
    const commonToolbarConfigTemplate: CustomButtonConfig = {
        buttonType: CustomButtonType.REGULAR_BUTTON,
        height: 40,
        icon: '',
        regularButtonIconEnable: true,
        transform: 'scale(1, 1)',
        toggle: false,
        toggleClass: 'toolbar-button-colour-on',
        width: 150,
        isDisabled: false
    }

    this.githubBtnConfig = this.generalHelper.clone_object(
        commonToolbarConfigTemplate);
    this.githubBtnConfig.icon = 'code';

    this.linkedinBtnConfig = this.generalHelper.clone_object(
        commonToolbarConfigTemplate);
    this.linkedinBtnConfig.icon = 'account_circle';

    this.stackoverflowBtnConfig = this.generalHelper.clone_object(
        commonToolbarConfigTemplate);
    this.stackoverflowBtnConfig.icon = 'reorder';

    this.mathstackexchangeBtnConfig = this.generalHelper.clone_object(
        commonToolbarConfigTemplate);
    this.mathstackexchangeBtnConfig.icon = 'calculate';
  }

  

  // * PRIVATE VARIABLES -------------------------------------------------------

}
