import { 
  Inject, 
  Injectable, 
  PLATFORM_ID 
} from '@angular/core';

import { 
  isPlatformBrowser 
} from '@angular/common';

import { 
  BreakpointObserver, 
  Breakpoints 
} from '@angular/cdk/layout';

import { 
  Observable, 
  Subject 
} from 'rxjs';

// DATA STRUCTURES -------------------------------------------------------------

import {
  WindowStates
} from 'src/app/data_structures/shared/window-states/window-states.ds';

@Injectable({
  providedIn: 'root'
})
export class ResizeHelperService {

  constructor(public breakpointObserver: BreakpointObserver,
      @Inject(PLATFORM_ID) private platformId: any) {
    // If the current platform is not the browser, then do NOT set up any window
    // listeners.
    if(isPlatformBrowser(this.platformId)) {
      // XSMALL Breakpoint observer setup
      breakpointObserver.observe([
        Breakpoints.XSmall
      ]).subscribe(result => {
        if(result.matches) {
          this.viewWindowState.next(WindowStates.XSMALL);
        }
      });

      // SMALL Breakpoint observer setup
      breakpointObserver.observe([
        Breakpoints.Small
      ]).subscribe(result => {
        if(result.matches) {
          this.viewWindowState.next(WindowStates.SMALL);
        }
      });

      // MEDIUM Breakpoint observer setup
      breakpointObserver.observe([
        Breakpoints.Medium
      ]).subscribe(result => {
        if(result.matches) {
          this.viewWindowState.next(WindowStates.MEDIUM);
        }
      });

      // LARGE Breakpoint observer setup
      breakpointObserver.observe([
        Breakpoints.Large
      ]).subscribe(result => {
        if(result.matches) {
          this.viewWindowState.next(WindowStates.LARGE);
        }
      });

      // XLARGE Breakpoint observer setup
      breakpointObserver.observe([
        Breakpoints.XLarge
      ]).subscribe(result => {
        if(result.matches) {
          this.viewWindowState.next(WindowStates.XLARGE);
        }
      });
    }
  }

  // * PUBLIC METHODS ----------------------------------------------------------

  /**
   * Determines the current window state and returns the enum specific 
   * breakpoint.
   * ! NOTE
   * ? While this could be made more efficient, this method only gets called
   * ? Once when the page loads and the state machine doesn't know what state
   * ? it should default to.
   */
   public get_current_screen_state() {
    if(isPlatformBrowser(this.platformId)) {
      const currentWindowSize = window.innerWidth / window.devicePixelRatio;
      // Start from the smallest size and move upward
      if( currentWindowSize < 600) {
        return WindowStates.XSMALL;
      } else if(currentWindowSize < 1024) {
        return WindowStates.SMALL;
      } else if(currentWindowSize < 1440) {
        return WindowStates.MEDIUM;
      } else if(currentWindowSize < 1920) {
        return WindowStates.LARGE;
      } else {
        return WindowStates.XLARGE;
      }
    } else {
      return WindowStates.SERVER;
    }
  }

  /**
   * Returns the observable for the screen width breakpoints.
   */
  public get_screen_width_observable() {
    return this.viewWindowState$;
  }

  // * PUBLIC VARIABLES --------------------------------------------------------

  public viewWindowState = new Subject<WindowStates>();

  public viewWindowState$ = this.viewWindowState.asObservable();

  // * PRIVATE METHODS ---------------------------------------------------------

  // * PRIVATE VARIABLES -------------------------------------------------------
}

