import {
    PostDataContainer,
    LinkButtonType,
    LinkContainer,
    MediaType
} from 'src/app/data_structures/shared/project_post/project_post.ds';

export let projectData: PostDataContainer[] = [

    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '06 / 06 / 2024',
        postDescription: `Decided to update my personal site and bring it online again. It is made with Angular and hosted on AWS. It mostly details projects I did a few years ago. Mostly just worked on NDA/private industry stuff and did other hobbies in my spare time since (Gardening, Gym, Travelling). I have done a a few other small tech projects recently to practice skills, but mostly on a laptop I wiped.`,
        postTitle: "This personal website",
        image1Url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/512px-Angular_full_color_logo.svg.png",
        image2Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/WelcomeImage.PNG",

        links: [
        ],
        postType: 'Web Development & Life Updates'
    },
    
    {
        backgroundColour: '',
        mediaType: MediaType.VIDEO,
        postDate: '07 / 05 / 2021',
        postDescription: `A vulnerability report of an older version of Wordpress. Covers an overview, scoping asset report, STRIDE threat model as well as an investigation of four discovered vulnerabilities: An insecure login form, a page leaked without authentication (CVE-2021-29450), a XSS attack via embeds (CVE-2017-6817) and an enumeration vulnerability. Below is a video detailing the login form exploit (done fast due to a file size limit on the original upload location)`,
        postTitle: "Vulnerability Report of WordPress 4.7.2",
        videoURL: "https://www.youtube.com/embed/OtXHR7khx8s",
        links: [ 
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/s45309889.pdf',
                linkText: 'View Doc',
                linkType: LinkButtonType.PDF
            }
        ],
        postType: 'Cyber Security'
    },

        // ? Operating System ----------------------------------------------------

        {
            backgroundColour: '',
            mediaType: MediaType.IMAGE,
            postDate: '06 / 10 / 2020',
            postDescription: `A modification of the BSD operating system that implements Solaris Zones (https://docs.oracle.com/cd/E19455-01/817-1592/zones.intro-1/index.html)`,
            postTitle: "BSD Implementation of Solaris Zones",
            image1Url: "https://docs.oracle.com/cd/E19455-01/817-1592/images/serv-con-zones_S10.gif",
            image2Url: "https://www.brendangregg.com/Zones/small-zone1.png",

            links: [
                {
                    link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/zonesImplementation.diff',
                    linkText: 'Download Patch',
                    linkType: LinkButtonType.DOWNLOAD
                },
            ],
            postType: 'Operating Systems'
        },

            // ? Disk Position Tool -----------------------------------------------

    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '28 / 8 / 2020',
        postDescription: `Quick tool for calculating the position on the cylinder disk after a fcfs, sstf, scan, look, cscan or clook operation.`,
        postTitle: "Disk Position Tool",
        image1Url: "https://www.101computing.net/wp/wp-content/uploads/hard-disk-platters-tracks-sectors.png",
        image2Url: "https://www.101computing.net/wp/wp-content/uploads/SCAN-Disk-Scheduling-Algorithm.png",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/disk_mov.py',
                linkText: 'Download Tool',
                linkType: LinkButtonType.DOWNLOAD
            }
        ],
        postType: 'Operating Systems'
    },


    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '24 / 10 / 2022',
        postDescription: `There is no project, just a photo of a cat. Enjoy.`,
        postTitle: "Nuclear Guidance System",
        image1Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/cat.png",
        image2Url: "",
        links: [
        ],
        postType: 'Cat'
    },  

    // ? Process Accounting Pseudo Device Driver ---------------------------------
    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '14 / 09 / 2020',
        postDescription: `Process accounting is a facility that allows administrators to audit the system resource utilisation of commands
        run by users.
        Process accounting in OpenBSD is currently implemented via the acct(2) system call that enables or disables
        of logging of commands to a file, the accton(8) utility for calling the syscall, and the sa(8) and lastcom(8)
        utilities for processing said file. The format of the file is described in the acct(5) manual page.
        A pseudo device driver is an actual driver, but not one that implements support for physical hardware. It
        provides a virtual, software only, service for user programs to use. This patch specifies the replacement of the acct(2) system call with a pseudo device driver that provides
        a device special file that produces messages analogous to the entries written to the accounting file.
        The acct(4) driver will provide a superset of the functionality that is provided by the current system call.
        The system call only records information about the process when it exits, but the driver will also report
        information about process forks and execs.`,
        postTitle: "Process Accounting Pseudo Device Driver",
        image1Url: "https://www.cs.uic.edu/~jbell/CourseNotes/OperatingSystems/images/Chapter3/3_04_ProcessSwitch.jpg",
        image2Url: "https://www.tutorialspoint.com/operating_system/images/pcb.jpg",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/accounting-boilerplate.diff',
                linkText: 'Download Patch',
                linkType: LinkButtonType.DOWNLOAD
            },
        ],
        postType: 'Operating Systems'
    },
    
  
    
    {
        backgroundColour: '',
mediaType: MediaType.IMAGE,
        postDate: '20 / 10 / 2020',
        postDescription: `I was part of a team that developed a mobile app. Recall is a mobile application and accompanying wearable device designed to help users living with dementia to recall recent conversations and overcome social difficulties associated with short-term memory loss. The primary function of the wearable device is to record conversations upon a button press and transfer these to the mobile application, which provides an interface for users to review their past conversations. The application was developed in Flutter/Dart. Speech diarisation was done using AWS Transcribe and Comprehend. Our project was a contender in the 2020 ITEE Best Biomedical Device or Systems Prize. We did not win, but we did recieve a separate invite from the Host (Cochlear) to visit and tour their office`,
        postTitle: "Recall: A companion app for those living with Dementia",
        image1Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/RecallScreenshot1.PNG",
        image2Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/RecallScreenshot2.PNG",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/RecallWhitepaper.pdf',
                linkText: 'View Whitepaper',
                linkType: LinkButtonType.PDF
            },
            {
                link: 'https://github.com/orgs/teamjawn/repositories',
                linkText: 'View Repos',
                linkType: LinkButtonType.GITHUB
            }
        ],
        postType: 'Mobile App'
    },
       

    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '26 / 10 / 2020',
        postDescription: `Written in java this is a my solution to a constrained optimisation problem that analyses how to find the maximum effective lifetime of mining equipment compared to maximisation of machine output and minimisation of unexpected failures. `,
        postTitle: "Constrained Optimisation Demo",
        image1Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/DrillExample.PNG",
        image2Url: "https://www.thedriller.com/ext/resources/Issues2/2021/03-March/nd-feat-brock3-web-032021.jpg",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/MiningDrillSpec.pdf',
                linkText: 'Spec',
                linkType: LinkButtonType.PDF
            },
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/JavaDrill.zip',
                linkText: 'Package Download',
                linkType: LinkButtonType.DOWNLOAD
            },
        ],
        postType: 'Optimisation'
    },
  
    // ? DAB Radio Project -----------------------------------------------------
    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '15 / 11 / 2019',
        postDescription: `A research paper on the optimisation of computational operations in two dimensional space. The paper compares the runtime efficiency of Conway's Game of Life (a simple, but customisable simulation) using naive looping verses Fourier transform operations.`,
        postTitle: "Optimisation of 2D Systems Using the Fourier Transform",
        image1Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/rand100start.png",
        image2Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/optimisedSizeRnad.png",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/Optimisation+of+2D+Systems+Using+the+Fourier+Transform.pdf',
                linkText: 'View Doc',
                linkType: LinkButtonType.PDF
            },
        ],
        postType: 'Optimisation' 
    },
    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '17 / 05 / 2020',
        postDescription: `A mock proposal I co-authored for a theoretical decentralised novel digital contract tracing solution. The project is privacy focused and makes use of a private Ethereum blockchain and contracts written in Solidity. Inter-device commincation makes use of Bluetooth Low Energy Protocol.`,
        postTitle: "Decentralised Contract Tracing",
        image1Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/BlutoDiagram1.PNG",
        image2Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/BlutoDiagram2.PNG",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/BlutoProposal.pdf',
                linkText: 'View Doc',
                linkType: LinkButtonType.PDF
            }
        ],
        postType: 'Blockchain'
    },

    // ? Scientific Computing I ------------------------------------------------

    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '06 / 05 / 2020',
        postDescription: `The objective of my study was to explore the popularity of keywords in the titles of films along with the
        distribution of genres across time. This metric was performed over the list of films as a whole; as
        well as comparative to how linguistic vocabulary in the titles change over time and the differences in
        vocabulary by genre. The ultimate goal was to verify if movie titles follow a
        Zipfian distribution.`,
        postTitle: "Language of the IMDB Movie Database",
        image1Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/25nonfunc.PNG",
        image2Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/Genre.PNG",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/MovieMagic.pdf',
                linkText: 'View Paper',
                linkType: LinkButtonType.PDF 
            },
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/DvisRetro.pdf',
                linkText: 'View Retrospective',
                linkType: LinkButtonType.PDF 
            }
        ],
        postType: 'Data Engineering & Analytics'
    },
    {
        backgroundColour: '',
mediaType: MediaType.IMAGE,
        postDate: '31 / 06 / 2020',
        postDescription: `Various cipher breaks and analyses. Covers Golay Codes (Error Correction), Affine Ciphers, Hill Cipers, Vigenère Ciphers, RSA breaking (via Chinese Remainder Theorum), Index of Coincidence pattern analysis. Also attatched is a few code snippets i used in the report`,
        postTitle: "Cryptography I",
        image1Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/Crypto11.PNG",
        image2Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/Crypto12.PNG",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/Crypto1.pdf',
                linkText: 'View Doc',
                linkType: LinkButtonType.PDF
            },
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/AssortedCrytoSnippets.zip',
                linkText: 'Download Snippets',
                linkType: LinkButtonType.DOWNLOAD
            }
        ],
        postType: 'Cryptography'
    },

    // ? Numerical Linear Algebra I --------------------------------------------

    {
        backgroundColour: '',
mediaType: MediaType.IMAGE,
        postDate: '04 / 07 / 2020',
        postDescription: `Various cipher breaks and analyses. Includes: LFSR stream ciphers, ElGamal encrpytion, Riffle shuffle analysis as permutations, S-Boxes and DES. Also attatched is a spreadsheet I used for assistance breaking BITXOR and DES Encryption`,
        postTitle: "Cryptography II",
        image1Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/Crypto21.PNG",
        image2Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/Crypto22.PNG",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/Crypto2.pdf',
                linkText: 'View Doc',
                linkType: LinkButtonType.PDF
            },
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/Crypto2.xlsx',
                linkText: 'View Spreadsheet',
                linkType: LinkButtonType.GITHUB
            }
        ],
        postType: 'Cryptography'
    },
    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '04 / 04 / 2020',
        postDescription: `Implementation of a mock TCP-like protocol and a network simulator using 
            python. The RUSHBProtocol package contains both a client and server for the protocol`,
        postTitle: "Network Engineering and Security I",
        image1Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/rushb1.png",
        image2Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/rushb2.jpg",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/NetworkProjects.zip',
                linkText: 'Download Projects',
                linkType: LinkButtonType.DOWNLOAD
            }
        ],
        postType: 'Network Engineering'
    },
    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '28 / 10 / 2020',
        postDescription: `Working with Snort, Nmap and IPTables in order to detect, attack and defend a number of networking scenarios. The paper contains walkthroughs for 7 tasks. An eighth task was removed due to the walkthrough screenshots containing sensitive info.`,
        postTitle: "Network Engineering and Security II",
        image1Url: "https://res.cloudinary.com/canonical/image/fetch/f_auto,q_auto,fl_sanitize,w_60,h_60/https://dashboard.snapcraft.io/site_media/appmedia/2016/03/nmap.png",
        image2Url: "https://images.ctfassets.net/o7xu9whrs0u9/43RRCktCxqEroj8eo9k17i/c820aac1ede6211ff9b790950ea3cb90/IPtables-logo.jpg",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/Snortnmapiptable.pdf',
                linkText: 'View Doc',
                linkType: LinkButtonType.DOWNLOAD
            }
        ],
        postType: 'Network Engineering'
    },
    // ? Scientific Computing II -----------------------------------------------

    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '10 / 07 / 2020',
        postDescription: `Made Pong (as in the game) in Python and then a 3D modelled version in Blender. Main goals of the project were just to explore Blender, work with angular trajectories and have a bit of fun.`,
        postTitle: "Making Pong in Blender and as a Python game 🏓",
        image1Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/CGview1.PNG",
        image2Url: "https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/CGview2.PNG",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/CGFinal.pdf',
                linkText: 'View Paper',
                linkType: LinkButtonType.PDF
            },
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/CGRetro.pdf',
                linkText: 'View Retrospective',
                linkType: LinkButtonType.PDF 
            }
        ],
        postType: 'Computer Graphics and Gaming'
    },

    // ? Scientific Computing III ----------------------------------------------

   
    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '24 / 08 / 2020',
        postDescription: `Various Exercises in Collab Notebooks on the topics of language technologies.\n
        Waveform Analysis: https://colab.research.google.com/drive/18wpK26pFJrOYSNhEhAIWexlFY5l-1CAr?usp=sharing \n
        N-Gram Analysis: https://colab.research.google.com/drive/1Qp7DAFUgbY6ZLGn94Pe4yMBhP1hTUS6I?usp=sharing \n
        Automatic Speech Recognition Model: https://sandbox.zenodo.org/record/671364#.X2Q2pD_iuiM \n


        `,
        postTitle: "Voyages in Language Technologies",
        image1Url: "https://i.imgur.com/1ggnSPd.png",
        image2Url: "https://i.imgur.com/rTfSGtv.png",
        links: [
            {
                link: 'https://docdro.id/BbVknuf',
                linkText: 'Translation',
                linkType: LinkButtonType.PDF
            },
            {
                link: 'https://docdro.id/T0GOS0Y',
                linkText: 'Text to Speech',
                linkType: LinkButtonType.PDF
            },     
        ],
        postType: 'Language Tech'
    },

    {
        backgroundColour: '',
        mediaType: MediaType.IMAGE,
        postDate: '19 / 07 / 2021',
        postDescription: `EDIT: This project sadly no longer works on the latest versions of Zapier 😭. This zap can be imported via the data management tab. It takes a winner an loser from the specified slack channel (Format: 🏓 {winner} beat {loser}) and constructs and updates an ELO controlled leaderboard in an Airtable table. This table can then be presented on a screen publically as the elo leaderboard. I sadly didn't get any photos of it when it was set up.`,
        postTitle: "(Deprecated) Slack -> Airtable Table tennis Leaderboard",
        image1Url: "https://upload.wikimedia.org/wikipedia/commons/7/79/Competitive_table_tennis.jpg",
        image2Url: "https://a.slack-edge.com/80588/marketing/img/meta/slack_hash_256.png",
        links: [
            {
                link: 'https://obpersonalportfolio.s3.ap-southeast-2.amazonaws.com/Elo+Updater+ZAP.json',
                linkText: 'Download Zap',
                linkType: LinkButtonType.GITHUB
            }  
        ],
        postType: 'API integration'
    },


    // ? Numerical Linear Algebra II -------------------------------------------

]
