<div class="main-container" #mainContainerRef
        [style.height]="mainBackgroundHeight"
        [style.offsetHeight]="toolbarBackgroundFillerHeight">
    
    <div class="posts-container" 
            *ngFor="let post of posts" (scroll)="handle_scroll_change($event)">
        <app-project-post #marker [inputData]="post"></app-project-post>
    </div>
</div>

