<h2 mat-dialog-title>Contact Details</h2>

<div class="mat-divider-soft-colour">
    <mat-divider></mat-divider>
</div>

<mat-dialog-content class="main-container mat-typography"
        [style.max-width]="dialogWidth">
        <div class="text-container margin-spacing">
            {{name}}
        </div>

        <div class="text-container margin-spacing">
            <mat-icon>mail</mat-icon>
            <span class="text-spacing">{{email}}</span>
        </div>

        <div class="text-container margin-spacing">
            <mat-icon>smartphone</mat-icon>
            <span class="text-spacing">{{phone}}</span>
        </div>

        <div class="margin-spacing button-container" [ngClass]="isBig ? 'buttons-container-big' : 'buttons-container-small'">
            <!-- Github -->
            <div [ngClass]="{'margin-spacing': !isBig}">
                <app-custom-button 
                    [inputText]="'Github'" 
                    [config]='githubBtnConfig' 
                    (clickEvent)='button_handler("github")'>
                </app-custom-button>
            </div>
    
            <!-- Linkedin -->
            <div [ngClass]="{'margin-spacing': !isBig}">
                <app-custom-button 
                    [inputText]="'Linkedin'" 
                    [config]='linkedinBtnConfig' 
                    (clickEvent)='button_handler("linkedin")'>
                </app-custom-button>
            </div>
    
            <!-- Stackoverflow -->
            <div [ngClass]="{'margin-spacing': !isBig}">
                <app-custom-button 
                    [inputText]="'Stack Overflow'" 
                    [config]='stackoverflowBtnConfig' 
                    (clickEvent)='button_handler("stackoverflow")'>
                </app-custom-button>
            </div>
        </div>
</mat-dialog-content>

<div class="mat-divider-soft-colour">
    <mat-divider></mat-divider>
</div>


<mat-dialog-actions>
    <!-- Exit buttons -->
    <button mat-flat-button
            class="button-flat-colour"
            (click)="button_handler('exit')">
        Exit
    </button>
</mat-dialog-actions>
