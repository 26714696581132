// ANGULAR ---------------------------------------------------------------------

import { 
  Component, 
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';

import {
  FormGroup,
  FormBuilder
} from '@angular/forms'

import {
  Observable
} from 'rxjs';

import { 
  map,
  startWith 
} from 'rxjs/operators';

// HELPERS ---------------------------------------------------------------------

import {
  ResizeHelperService
} from 'src/app/services/resize_helper/resize-helper.service';

// INTERFACES ------------------------------------------------------------------

import {
  WindowResize
} from 'src/app/services/resize_helper/resize-helper.interface';

// DATA ------------------------------------------------------------------------

import {
  projectData
} from 'src/app/pages/homepage/projects';

// DATA STRUCTURES -------------------------------------------------------------

import { 
  WindowStates 
} from 'src/app/data_structures/shared/window-states/window-states.ds';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, WindowResize {

  @Output() searchBarOutput = new EventEmitter<string>();

  constructor(private _formBuilder: FormBuilder,
      private windowResizeHelper: ResizeHelperService) { 
    this.searchOptions = [];
    for(let i = 0; i < projectData.length; i++) {
      this.searchOptions.push(projectData[i].postTitle);
    }

    this.windowResizeHelper.get_screen_width_observable().subscribe(result => {
        this.window_state_change(result);
    });

    // Get the initial window state
    this.window_state_change(
        this.windowResizeHelper.get_current_screen_state());
  }

  ngOnInit(): void {
    this.init_text_filter_options();
  }

  // * PUBLIC METHODS ----------------------------------------------------------

  public window_state_change(newState: WindowStates) {
    switch(newState) {
      case WindowStates.XSMALL:
        // Resize elements to be XSMALL (x < 600)
        this.formWidth = '70vw';
        break;
      case WindowStates.SMALL:
        // Resize elements to be SMALL (600 <= x <1024)
        this.formWidth = '450px';
        break;
      case WindowStates.MEDIUM:
        // Resize elements to be MEDIUM (1024 <= x < 1440)
        this.formWidth = '450px';
        break;
      case WindowStates.LARGE:
        // Resize elements to be LARGE (1440 <= x < 1920)
        this.formWidth = '450px';
        break;
      case WindowStates.XLARGE:
        // Resize elements to be XLARGE (1920 <= x)
        this.formWidth = '450px';
        break;
      case WindowStates.SERVER:
        
        break;
      default:
        console.error("Unknown windows state: " + newState);
    }
}

  public user_search_input_handler($event: KeyboardEvent | MouseEvent) {
    if((this.is_keyboard_event($event) && $event.key == 'Enter') ||
        (this.is_mouse_event($event) && $event.type == 'click')) {
      // Get the data from the user input field
      const formData = this.searchbarForm.get('filterTerm').value;

      // Check to see if the input data matches anything in the search options 
      //  list
      for(let i = 0; i < this.searchOptions.length; i++) {
        if(this.searchOptions[i].includes(formData)) {
          // We found a matching option, let's emit what it is
          this.searchBarOutput.emit(this.searchOptions[i]);
          break;
        }
      }
    } 
  }

  // * PUBLIC VARIABLES --------------------------------------------------------

  public searchOptionsGroup: Observable<string[]>;
  public searchOptions: string[];

  public searchbarForm: FormGroup = this._formBuilder.group({
    filterTerm: '',
  });

  public formWidth: string = '350px';

  // * PRIVATE METHODS ---------------------------------------------------------

  /**
   * Filter method for the searchbar. Simple indexing method for finding
   * substrings that match the start of application menuLinkText strings.
   * @param opt The list of application menuLinkText items
   * @param value The users input string
   * @returns a list of applications containing the users input string at the
   *        start of input.
   */
   public _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.searchOptions.filter(option => 
        option.toLowerCase().includes(filterValue));
  }

  private init_text_filter_options() {
    this.searchOptionsGroup = this.searchbarForm.get('filterTerm')!.valueChanges
      .pipe(startWith(''),
      // 
      map(value => this._filter(value || '')))
  }

  /**
   * User defined type guard for a keyboard event.
   * Useful for figuring out what type the input is without have having nested
   * if statements
   * @param $event The event to be checked. 
   * @returns A boolean which is true if the event is a keyboard event.
   */
  private is_keyboard_event($event: KeyboardEvent | MouseEvent): $event is KeyboardEvent {
    return ($event as KeyboardEvent).type !== undefined;
  }

  /**
   * User defined type guard for a mouse event.
   * Useful for figuring out what type the input is without have having nested
   * if statements
   * @param $event The event to be checked. 
   * @returns A boolean which is true if the event is a mouse event.
   */
  private is_mouse_event($event: KeyboardEvent | MouseEvent): $event is MouseEvent {
    return ($event as MouseEvent).type !== undefined;
  }



  // * PRIVATE VARIABLES -------------------------------------------------------

}
