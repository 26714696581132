import { 
    Component,
    ViewChild,
    AfterViewInit,
    ChangeDetectorRef,
    Inject,
    // SSR stuff
    PLATFORM_ID,
    ElementRef
} from '@angular/core';

import {
    isPlatformBrowser,
    isPlatformServer
  } from '@angular/common';
  
  import { 
    MatDialog 
  } from '@angular/material/dialog';
  
import {
    FormGroup,
    FormBuilder
} from '@angular/forms'


// HELPERS ---------------------------------------------------------------------

import {
    GeneralHelperService
} from 'src/app/services/general_helper/general-helper.service';

import {
    ThemeTrackerService
} from 'src/app/services/theme_tracker/theme-tracker.service';

import {
    ResizeHelperService
} from 'src/app/services/resize_helper/resize-helper.service';

// INTERFACES ------------------------------------------------------------------

import {
    WindowResize
} from 'src/app/services/resize_helper/resize-helper.interface';

// COMPONENTS ------------------------------------------------------------------

import {
    WaveFunctionGeneratorComponent
} from 'src/app/subcomponents/wave-function-generator/wave-function-generator.component';

import {
    HomepageComponent
} from 'src/app/pages/homepage/homepage.component';

import {
    ContactDetailsComponent
} from 'src/app/dialog_menus/contact-details/contact-details.component';

// ENUMS -----------------------------------------------------------------------

import {
    FormType
} from 'src/app/data_structures/specific/text-input-field/text-input-field.ds';

// DATA STRUCTURES -------------------------------------------------------------

import {
    FormConfig
} from 'src/app/data_structures/specific/text-input-field/text-input-field.ds';


import {
    CustomButtonClickEvent,
    CustomButtonConfig,
    CustomButtonType
} from 'src/app/data_structures/specific/custom-button/custom-button.ds';

import { 
    WindowStates 
} from 'src/app/data_structures/shared/window-states/window-states.ds';

import {
    ContactDetailsDialogData, 
    DialogSizeMode
} from 'src/app/data_structures/shared/contact_details/contact_details.ds';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, WindowResize {
    title = 'portfolio-website';

    @ViewChild('wavegenRef') wavegenRef: WaveFunctionGeneratorComponent;

    @ViewChild('homepageRef') homepageRef: HomepageComponent;

    @ViewChild('mainContainerRef') mainContainerRef: ElementRef;

    constructor(private generalHelper: GeneralHelperService,
            @Inject(PLATFORM_ID) private platformId: any,
            private windowResizeHelper: ResizeHelperService,
            private themeTrackerHelper: ThemeTrackerService,
            private dialog: MatDialog,
            private cd: ChangeDetectorRef,
            private _formBuilder: FormBuilder) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        if(this.isBrowser) {
            // Initialise the current window size elements
            this.windowResizeHelper.get_screen_width_observable().subscribe(result => {
                this.window_state_change(result);
            });
          
            // Get the initial window state
            this.window_state_change(
                this.windowResizeHelper.get_current_screen_state());

            // Display the browser stuff

            // Init stuff
            this.dark_theme_init();
            this.toolbar_button_init();

        } else {
            // This is the server, do server stuff
        }
    }

    ngAfterViewInit(): void {
        this.cd.detectChanges();
    }

    // * PUBLIC METHODS --------------------------------------------------------
    
    public searchbar_handler() {

    }
    
    public window_state_change(newState: WindowStates) {
        this.currentWindowState = newState;
        switch(newState) {
          case WindowStates.XSMALL:
            // Resize elements to be XSMALL (x < 600)
            this.toolbarBackgroundFillerHeight = '56px';
            this.mainBackgroundHeight = 'calc(100% - 56px)';
            this.dialogWindowWidth = '90vw';
            break;
          case WindowStates.SMALL:
            // Resize elements to be SMALL (600 <= x <1024)
            this.toolbarBackgroundFillerHeight = '64px';
            this.mainBackgroundHeight = 'calc(100% - 64px)';
            this.dialogWindowWidth = '600px';
            break;
          case WindowStates.MEDIUM:
            // Resize elements to be MEDIUM (1024 <= x < 1440)
            this.toolbarBackgroundFillerHeight = '64px';
            this.mainBackgroundHeight = 'calc(100% - 64px)';
            this.dialogWindowWidth = '600px'
            break;
          case WindowStates.LARGE:
            // Resize elements to be LARGE (1440 <= x < 1920)
            this.toolbarBackgroundFillerHeight = '64px';
            this.mainBackgroundHeight = 'calc(100% - 64px)';
            this.dialogWindowWidth = '600px'
            break;
          case WindowStates.XLARGE:
            // Resize elements to be XLARGE (1920 <= x)
            this.toolbarBackgroundFillerHeight = '64px';
            this.mainBackgroundHeight = 'calc(100% - 64px)';
            this.dialogWindowWidth = '600px'
            break;
          case WindowStates.SERVER:
            
            break;
          default:
            console.error("Unknown windows state: " + newState);
        }
    }

    public scroll_change_handler(scrollPosition: number) {
        this.scrollbar_debouncer(scrollPosition);
    }

    public searchbar_output_handler($event: string) {
        this.homepageRef.scroll_to_project($event);
    }

    public handle_scroll_change($event) {
        var h = $event.target, 
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';

        // Emit an event to the parent.
        const scrollP = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 
                100;
        
        this.wavegenRef.set_scrollbar_position(scrollP);
    }

    public colour_change_handler(newColour: string) {
        this.toolbarBackgroundColour = newColour;
    }

    public toolbar_button_handler(type: string) {
        switch(type) {
            case 'settings':
                break;

            case 'toggle-dark-mode':
                this.toggle_dark_mode();
                break;

            case 'contact-details':
                let sizeMode: DialogSizeMode = DialogSizeMode.BIG;
                if(this.currentWindowState == WindowStates.XSMALL) {
                    sizeMode = DialogSizeMode.SMALL;
                }

                const dataToSend: ContactDetailsDialogData = {
                    name: "Oscar Bradford",
                    email: "oscar.bradford13@gmail.com",
                    phone: "+61 0480 149 080",
                    githubURL: "https://github.com/OscarBrad",
                    linkedinURL: "https://www.linkedin.com/in/oscar-bradford-3a10b8190/",
                    stackoverflowURL: "https://stackoverflow.com/users/20245857/oscar-bradford",
                    mathstackexchangeURL: "",
                    mode: sizeMode
                }

                const dialogRef = this.dialog.open(ContactDetailsComponent, {
                    width: this.dialogWindowWidth,
                    data: dataToSend
                });

                dialogRef.afterClosed().subscribe(result => {
                    // Don't need to do anything so I left this blank
                });
                break;
            default:
                console.error("Unknown button type: " + type);
        }
    }

    public intro_done_handler() {
        this.showIntroWindow = false;
    }

    // * PUBLIC VARIABLES ------------------------------------------------------

    public showToolbar: boolean = true;
    public isDarkTheme: boolean = false;

    public toolbarBackgroundColour: string;

    // ? Toolbar buttons
    public settingsButtonConfig: CustomButtonConfig;
    public settingsButtonText = 'Settings';
    public mainBackgroundHeight = 'calc(100% - 64px)';
    public toolbarBackgroundFillerHeight = '64px';

    public currentWindowState: WindowStates;

    // ? Intro window variables
    public showIntroWindow: boolean = true;

    // ? Is browser variables
    public isBrowser: boolean = false;

    // ? Searchbar form config
    public searchbarFormConfig: FormConfig;
    
    public filterForm: FormGroup = this._formBuilder.group({
        filterTerm: '',
    });
    

    // * PRIVATE METHODS -------------------------------------------------------

    private dark_theme_init() {
        if(this.themeTrackerHelper.get_theme() == 'dark') {
            this.isDarkTheme = true;
            this.toolbarBackgroundColour = "#313131";
        } else {
            this.isDarkTheme = false;
            this.toolbarBackgroundColour = "#f44336";
        }
    }

    private scrollbar_debouncer(newPosition: number) {
        if(this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(
                this.send_scrollbar_position_to_wavegen.bind(this), 
                this.debounceDuration);
        
        this.newPosition = newPosition
    }

    private send_scrollbar_position_to_wavegen() {
        this.wavegenRef.set_scrollbar_position(this.newPosition);
    }

    private toolbar_button_init() {
        const commonToolbarConfigTemplate: CustomButtonConfig = {
            buttonType: CustomButtonType.TOOLBAR_BUTTON,
            height: 40,
            icon: '',
            regularButtonIconEnable: true,
            transform: 'scale(1, 1)',
            toggle: false,
            toggleClass: 'toolbar-button-colour-on',
            width: 40,
            isDisabled: false
        }

        this.settingsButtonConfig = this.generalHelper.clone_object(
            commonToolbarConfigTemplate);
        this.settingsButtonConfig.icon = 'more_vert';
    }

    private toggle_dark_mode() {
        if(this.isDarkTheme) {
            this.isDarkTheme = false;
            this.themeTrackerHelper.set_theme('light');
        } else {
            this.isDarkTheme = true;
            this.themeTrackerHelper.set_theme('dark');
        }
    }
    

    // * PRIVATE VARIABLES -----------------------------------------------------

    private timeoutId;
    private newPosition
    private debounceDuration: number = 50;

    // ? dialog window width
    private dialogWindowWidth: string = "600px";

}
