import { 
  Component, 
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  EventEmitter
} from '@angular/core';

import {
  FormGroup
} from '@angular/forms';

import {
  FormConfig,
  FormType
} from '../../data_structures/specific/text-input-field/text-input-field.ds';

@Component({
  selector: 'app-text-input-field',
  templateUrl: './text-input-field.component.html',
  styleUrls: ['./text-input-field.component.scss']
})
export class TextInputFieldComponent implements OnInit, AfterViewInit {

  // Form stuff
  @Input() config: FormConfig;

  @Output() formUpdate = new EventEmitter<string>();

  // A reference to the textarea input field.
  @ViewChild('textareaInput') summaryInput: ElementRef | undefined;

  constructor() { 
      this.showForm = false;
  }

  ngOnInit(): void {
      switch(this.config.type) {
          case FormType.TEXTAREA_FORM:
              //!NOTE
              // Make sure you initialise these FIRST before showing the form
              // (avoids undefined error)
              this.summaryCharRemaining = this.config.maxCharacters.
                      toString();
              this.summaryRowsRemaining = (this.config.maxRows - 
                      this.minNumRows).toString();

              this.showTextareaForm = true;
              this.showDatepickerForm = false;
              this.showInputForm = false;

              this.load_textarea_check_height();

              
              // Initialise the textarea variables
              
              break;
          case FormType.DATE_PICKER_FORM:
              this.showTextareaForm = false;
              this.showDatepickerForm = true;
              this.showInputForm = false;
              break;
          case FormType.INPUT_FORM:
              this.titleCharRemaining = this.config.maxCharacters.toString();

              this.text_limit_update('title');

              this.showTextareaForm = false;
              this.showDatepickerForm = false;
              this.showInputForm = true;
              
              break;
          default:
              // TODO : Error handling
              console.error("Unknown form configuration -> " + 
                      this.config.type);
      }
      this.showForm = true;
  }

  ngAfterViewInit(): void {
      if(this.config.type == FormType.TEXTAREA_FORM) {
          const textAreaHeightString = this.summaryInput.nativeElement.style.
                  height;
          
          this.defaultRowHeight = parseInt(
                  textAreaHeightString.substring(0, textAreaHeightString.length - 
                  2))/2;

          // TODO - fix this being initialised when display is set to none;
      }
  }

  private async load_textarea_check_height() {
      while(this.summaryInput == undefined) { 
          await new Promise(f => setTimeout(f, 500));
      }
      this.text_limit_update('summary');
      this.check_summary_height();
  }

  //* PUBLIC METHODS ---------------------------------------------------------
  /**
   * Keeps track of the number of characters the user has left. If the user 
   * goes over the limit, the function will remove any additional characters.
   * @param type The element that is getting input (i.e. the summary 
   *      description or the title).
   */
  public text_limit_update(type: string) {
      // Auto-resize
      switch(type) {
          case 'summary':
              // Check the height of the summary container
              const textAreaHeightString = 
                  this.summaryInput.nativeElement.style.height;
              const textAreaHeightValue = parseInt(textAreaHeightString.
                      substring(0, textAreaHeightString.length - 2));
              
              if(textAreaHeightValue > this.config.maxRows*
                      this.defaultRowHeight) {
                  const oldValue = this.summaryInput.nativeElement.value;
                  this.summaryInput.nativeElement.value = oldValue.
                          substring(0, oldValue.length - 1);
                  this.summaryRowsRemaining = '0';
              } else {
                  // ! WARNING - This is only an estimation of row height, 
                  // ! test on multiple browsers before deployment.
                  const rowsRemaining = Math.floor(this.config.maxRows - 
                      textAreaHeightValue/this.defaultRowHeight);
                  
                  // Check to see if the user is trying to paste their way through
                  if(rowsRemaining == 0) {
                      // Get the first (max length of text input) and discard the rest.
                      const summary = this.config.parentForm.get(
                          this.config.formControlName);
                      this.config.parentForm.get(this.config.formControlName)
                              .setValue(summary.value.substring(0, 
                              this.config.maxCharacters));
                  }
                  this.summaryRowsRemaining = rowsRemaining.toString();
              }
              // Check the number of words in the summary box
              const summary = this.config.parentForm.get(this.config.
                      formControlName);
              if(summary != null) {
                  // Get length of the string
                  if(summary.value == null) {
                      this.summaryCharRemaining = (this.config.maxCharacters).
                              toString();
                  } else {
                      const summaryLength = summary.value.length;
                      // Update the number of characters remaining
                      this.summaryCharRemaining = (this.config.maxCharacters 
                              - parseInt(summaryLength)).toString();
                  } 
              } 
              break;
          case 'title':
              const title = this.config.parentForm.get(this.config.
                      formControlName);
              if(title != null) {
                  // Get length of the string
                  if(title.value == null) {
                      this.titleCharRemaining = (this.config.maxCharacters).
                              toString();
                  } else {
                      const titleLength = title.value.length;
                      this.titleCharRemaining = (this.config.maxCharacters - 
                              parseInt(titleLength)).toString();
                  }
              }
              break;
          default:
              console.error("Unknown text limit type: " + type);
      }
      // Check if we need to alert the parent that something has changed
      if(this.config.emitUpdate) {
          // Tell the parent which form control name sent the update
          this.formUpdate.emit(this.config.formControlName);
      }
  }

  /**
   * Keeps track of the number of rows the post currently takes up. If the 
   * user goes over the maximum number of rows, the function will remove the 
   * last character (whether that be '\n' or any other character triggering a 
   * wrap-around).
   */
  public check_summary_height() {
      const textAreaHeightString = this.summaryInput.nativeElement.style.
              height;
      const textAreaHeightValue = parseInt(textAreaHeightString.substring(0, 
          textAreaHeightString.length - 2));
          
      if(textAreaHeightValue > this.config.maxRows * this.defaultRowHeight) {
          const oldValue = this.summaryInput.nativeElement.value;
          this.summaryInput.nativeElement.value = oldValue.substring(0, 
                  oldValue.length - 1);
          this.summaryRowsRemaining = '0';
      } else {
      // ! WARNING - This is only an estimation of row height, test on multiple
      // ! browsers before deployment.
      this.summaryRowsRemaining = 
          Math.floor(this.config.maxRows - textAreaHeightValue/
                  this.defaultRowHeight).toString();
      }
  }


  //* PUBLIC VARIABLES -------------------------------------------------------
  public showForm: boolean;
  public showTextareaForm: boolean;
  public showInputForm: boolean;
  public showDatepickerForm: boolean;

  public summaryCharRemaining: string;
  public summaryRowsRemaining: string;

  public titleCharRemaining: string;

  public minNumRows: number = 3;

  //* PRIVATE VARIABLES ------------------------------------------------------

  private defaultRowHeight: number;
}


