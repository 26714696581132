<div class="main-container">
    <div class="search-bar-input-container input-field-colour">
        <form [formGroup]="searchbarForm" class="search-bar-form-container"
                [style.width]="formWidth">
            <mat-form-field class="mat-form-container" appearance="standard">
                <mat-label>Search Projects</mat-label>
                <input type="text"
                        placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                        matInput
                        formControlName="filterTerm"
                        [matAutocomplete]="auto"
                        (keyup)="user_search_input_handler($event)">
                <mat-autocomplete #auto="matAutocomplete">
                    
                    <mat-option class="options-text-colour" 
                            *ngFor="let group of searchOptionsGroup | async"
                            (click)="user_search_input_handler($event)"
                            [value]="group">
                        {{group}}
                    </mat-option>
                    
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>
</div>