// * CORE
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

/**
 * Options for 'formConfig'
 */
 export enum FormType {
    TEXTAREA_FORM,
    INPUT_FORM,
    DATE_PICKER_FORM
  }
  
  /**
   * Class intended as a configuration object for a textarea form component.
   */
  export class FormConfig {
    parentForm: FormGroup;
    formLabel: string;
    formControlName: string;
    maxRows: number;
    maxCharacters: number;
    placeholderDescription: string;
    
    // Styling attributes
    formWidth: string;
    type: FormType;
    showCount: boolean;
    
    // Component specific

    // ? Required for INPUT_FORM option, specify
    // ?  either 'text' or 'password
    inputType: string | undefined;

    // ?
    emitUpdate: boolean | undefined;
  }
