<div class="main-container container-background-colour box-shadow-outline" 
        #mainContainerRef
        [style.visibility]="postVisibility"
        [style.max-width]="postWidth"
        (window:resize)="window_resize_event_handler($event)">
    <!-- Left spine -->
    <div class="left-spine" 
        [style.height]="spineHeight"
        [style.background-color]="backgroundColour"
        [style.width]="spineWidth">
    </div>

    <!-- Content -->
    <div class="content-container" #contentContainerRef>
        <!-- Top stuff -->
        <div class="top-stuff-container">
            <div>
                {{postDate}}
            </div>

            <div class="mat-divider-soft-colour divider">
                /
            </div>

            <div>
                {{postType}}
            </div>
            
        </div>

        <!-- Title -->
        <div class="title-container">
            {{postTitle}}
        </div>
        
        <!-- Description -->
        <div class="post-description">
            {{postDescription}}
        </div>

        <!-- Images -->
        <div class="images-container" *ngIf="isImage">
            <!-- Image 1 -->
            <div class="image-sub-container" [style.width]="imageWidth">
                <img class="image-style" [src]="image1Url">
            </div>

            <!-- Image 2 -->
            <div class="image-sub-container" [style.width]="imageWidth">
                <img class="image-style" [src]="image2Url">
            </div>
        </div>

        <!-- Video -->
        <div *ngIf="isVideo">
            <iframe 
                    width="540" 
                    height="315" 
                    [src]="youtubeURL" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
            </iframe>
        </div>

        <!-- Buttons -->
        <div class="buttons-container">
            <div *ngFor="let button of buttons">
                <app-custom-button 
                    [inputText]="button.text" 
                    [config]='button.config' 
                    (clickEvent)='button_handler(button.id)'>
                </app-custom-button>
            </div>
        </div>

    </div>
    
</div>
