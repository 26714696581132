<div class="main-container" [style.pointerEvents]="pointerEvents">
    <!-- Regular button -->
    <div matRipple class="display-button-style"
            *ngIf="isRegularButton"
            (keydown.enter)="button_click('enter')"
            tabindex="0" 
            [ngClass]="hasClicked ? toggleClass : 'button-flat-colour'"
            [style.height]='buttonHeight'
            [style.width]='buttonWidth'
            (click)="button_click('mouse')">
        <!-- Button text -->
        <div>
            {{buttonText}}
        </div>

        <!-- Icon (optional) -->
        <div *ngIf="buttonIconEnable" class="button-icon-style">
            <mat-icon>{{buttonIcon}}</mat-icon>
        </div>
    </div>

    <!-- Icon button (without background) -->
    <div class="display-icon-style"
            *ngIf="isIconButton"
            (keydown.enter)="button_click('enter')"
            tabIndex="0"
            [ngClass]="hasClicked ? toggleClass : 'button-icon-colour'"
            [style.height]='buttonHeight'
            [style.width]='buttonWidth'
            (click)="button_click('mouse')">
        <mat-icon [style.transform]="transformFactor">{{buttonText}}</mat-icon>
    </div>

    <!-- Icon button (with background) -->
    <div class="display-icon-style"
            matRipple
            *ngIf="isIconButtonWBackground"
            (keydown.enter)="button_click('enter')"
            tabIndex="0"
            [ngClass]="hasClicked ? toggleClass : 'button-icon-colour-w-bg'"
            [style.height]='buttonHeight'
            [style.width]='buttonWidth'
            (click)="button_click('mouse')">
        <mat-icon [style.transform]="transformFactor">{{buttonText}}</mat-icon>
    </div>

    <!-- Toolbar button -->
    <div class="display-toolbar-button"
            matRipple
            *ngIf="isToolbarButton"
            (keydown.enter)="button_click('enter')"
            [matTooltip]="buttonText"
            tabIndex="0"
            [ngClass]="hasClicked ? toggleClass : 'toolbar-button-colour'"
            [style.height]='buttonHeight'
            [style.width]='buttonWidth'
            (click)="button_click('mouse')"
            [matTooltipShowDelay]="matTooltipDelay"
            matTooltipClass="mat-tooltip-colours">
        <div class="toolbar-icon-style">
            <mat-icon [style.transform]="transformFactor">{{buttonIcon}}</mat-icon>
        </div>
    </div>
</div>

