// ANGULAR ---------------------------------------------------------------------

import { 
    Component, 
    OnInit,
    ViewChild,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

// THIRD PARTY -----------------------------------------------------------------

const cinterp = require('color-interpolate');

// HELPERS ---------------------------------------------------------------------

import {
    StableRingHelperService
} from 'src/app/services/stable_ring_helper/stable-ring-helper.service';

import {
    ThemeTrackerService
} from 'src/app/services/theme_tracker/theme-tracker.service';

// INTERFACES ------------------------------------------------------------------

// APIS ------------------------------------------------------------------------

// COMPONENTS ------------------------------------------------------------------

// ENUMS -----------------------------------------------------------------------

// DATA STRUCTURES -------------------------------------------------------------


@Component({
  selector: 'app-wave-function-generator',
  templateUrl: './wave-function-generator.component.html',
  styleUrls: ['./wave-function-generator.component.scss']
})
export class WaveFunctionGeneratorComponent implements AfterViewInit {

    @ViewChild('canvasRef') canvasRef: ElementRef<HTMLCanvasElement>;

    @Output() colourChangeEvent = new EventEmitter<string>();

    constructor(private cd: ChangeDetectorRef,
            private themeTrackerHelper: ThemeTrackerService,
            private stableRing: StableRingHelperService) { 

    }

    ngAfterViewInit(): void {
        this.context = this.canvasRef.nativeElement.getContext('2d');
        this.init_wave_canvas();
        this.init_dark_theme();
        this.cd.detectChanges();
    }


    // * PUBLIC METHODS --------------------------------------------------------

    public set_scrollbar_position(newPosition: number) {
        this.scrollPosition = newPosition;
        this.scrollbar_to_lambda_projection();
    }

    public set_horiz_offset(horizOffset: number) {
        this.horizOffset = horizOffset;
    }


    // * PUBLIC VARIABLES ------------------------------------------------------

    public context: CanvasRenderingContext2D;
    public tickRef: any;

    // * PRIVATE METHODS -------------------------------------------------------

    private init_dark_theme() {
        if(this.themeTrackerHelper.get_theme() == 'dark') {
            this.currentColour = this.colourInterpDark(0);
        } else {
            this.currentColour = this.colourInterp(0);
        }
     }

    private init_wave_canvas() {
        this.canvasRef.nativeElement.width = outerWidth;
        this.canvasRef.nativeElement.height = 200;

        this.animate();

        // Start timestep function
        this.tick();
        
    }


    private animate() {
        this.canvasRef.nativeElement.width = innerWidth;
        this.canvasRef.nativeElement.height = 400;

        // Clear the path
        this.context.clearRect(0,0,this.canvasRef.nativeElement.width - 
                this.horizOffset, 
                this.canvasRef.nativeElement.height);

        this.context.beginPath();
        
        // Starting point on the left side of the screen
        this.context.moveTo(0, this.canvasRef.nativeElement.height 
                * this.waveOffsetPercent + 
                this.wave_mixer(0));

        // Draw the waveform
        for (let i = 0; i < this.canvasRef.nativeElement.width - 
                    this.horizOffset; i++) {
            this.context.lineTo(i, 
                    this.canvasRef.nativeElement.height 
                    * this.waveOffsetPercent + 
                    this.wave_mixer(i));
        }

        // Line from the right side to the bottom right
        this.context.lineTo(this.canvasRef.nativeElement.width - this.horizOffset, 
                this.canvasRef.nativeElement.height);

        // Line from the bottom right side to the bottom left side
        this.context.lineTo(0, 
            this.canvasRef.nativeElement.height);

        // Line from the bottom left to the starting point
        this.context.lineTo(0, this.canvasRef.nativeElement.height 
                * this.waveOffsetPercent + 
                this.wave_mixer(0));
        
        if(this.themeTrackerHelper.get_theme() == 'dark'){
            this.context.lineWidth = 10;
            this.context.strokeStyle = this.currentStrokeColour;
            this.context.stroke();
        } 

        this.context.fillStyle = this.currentColour;
        this.context.fill();
        
    }

    private scrollbar_to_lambda_projection() {
        this.lambda = this.minLambda + 
                (this.maxLambda - this.minLambda)*(this.scrollPosition/100);
    }

    private wave_mixer(i: number) {
        if(this.currentR <= this.lowerR1) {
            return this.sine_wave(i);

        } else if(this.currentR > this.lowerR1 && 
                this.currentR < this.upperR1) {
            const sp = this.currentR;
            const proj = (sp - this.lowerR1)*(100/(this.upperR1 - this.lowerR1))
            return (1 - proj/100)*this.sine_wave(i) + 
                    (proj/100)*this.triangle_wave(i);

        } else if(this.currentR >= this.upperR1 && this.currentR <= this.lowerR2) {
            return this.triangle_wave(i);

        } else if(this.currentR > this.lowerR2 && this.currentR < this.upperR2) {
            const sp = this.currentR;
            const proj = (sp - this.lowerR2)*(100/(this.upperR2 - this.lowerR2))
            return (1 - proj/100)*this.triangle_wave(i) + 
                    (proj/100)*this.square_wave(i);

        } else {
            return this.square_wave(i);
        }
    }

    private sine_wave(i: number) {
        return this.amplitude * Math.sin(i * this.period + this.theta);
    }

    private triangle_wave(i: number) {
        return ((2 * this.amplitude) / Math.PI) * Math.asin(Math.sin(
                2*Math.PI / (2*(1/this.period)) * i + this.theta));

    }

    private square_wave(i: number) {
        const step1 = this.amplitude/2 * Math.sign(
                Math.sin(2*Math.PI * this.period/3 * i + this.theta + Math.PI/2));
        const step2 = this.amplitude/2 * Math.sign(
                Math.sin(2*Math.PI * this.period/3 * i + this.theta));
        return (step1 + step2);
    }

    private tick() {
        // TODO - determine wrap around lowest common factor
        // Square wave = 119
        // Sine wave = ?
        // Triangle wave = ?
        
        this.animate();

        this.update_state();

        this.colour_mixer();

        // Convert the x and y terms to theta
        this.theta = this.stableRing.convert_x_y_to_theta(this.x, this.y);
        this.currentR = this.stableRing.convert_x_y_to_r(this.x, this.y);

        let _this = this;
        setTimeout(function() {
            
            _this.tick();

        }, this.tickTimeMs);
    }

    private update_state() {
        this.x += this.stableRing.dx(this.x, this.y, this.lambda) * 
                this.stepSize;
        this.y += this.stableRing.dy(this.x, this.y, this.lambda) * 
                this.stepSize;
    }

    private colour_mixer() {
        const cr = (this.currentR - this.minR)*(1/(this.maxR - this.minR))
        
        // Check the current theme
        if(this.themeTrackerHelper.get_theme() == 'dark') {
            this.currentColour = this.colourInterpDark(cr);
            this.currentStrokeColour = this.colourInterp(cr);
        } else {
            this.currentColour = this.colourInterp(cr);
        }

        this.colourChangeEvent.emit(this.currentColour);
    }

    // * PRIVATE VARIABLES -----------------------------------------------------
    
    // Our points
    private x: number = 0.5;
    private y: number = 0.5;
    private stepSize: number = 10*Math.pow(10, -3.5);
    private theta: number;
    private lambda: number = 4;

    private currentR: number = 2;
    private minR: number = 2;
    private maxR: number = 4;
    

    // Scroll position
    private scrollPosition: number = 0;
    private horizOffset: number = 0;

    // Scroll ranges for cutoff
    private minLambda = Math.pow(this.minR,2);
    private maxLambda = Math.pow(this.maxR,2);

    // Radius ranges for cutoff
    private lowerR1: number = 2.5;
    private upperR1: number = 2.7;

    private lowerR2: number = 3.2;
    private upperR2: number = 3.4;

    // Colour
    private currentColour: string;
    private currentStrokeColour: string;

    private tickTimeMs = 20;

    private amplitude = 100;
    private period = 0.01;


    private waveOffsetPercent = 0.5;

    private colourInterp = cinterp(['#f44336', '#ffc107', '#4caf50']);
    private colourInterpDark = cinterp(['#2E2E2E']);

}
