import {
    CustomButtonConfig
} from 'src/app/data_structures/specific/custom-button/custom-button.ds';


export enum LinkButtonType {
  GITHUB,
  PDF,
  DOWNLOAD
}

export enum MediaType {
    VIDEO = 1,
    IMAGE
}

export class LinkContainer {
    linkType: LinkButtonType;
    link: string;
    linkText: string;
}

export class CustomButtonContainer {
    id: string;

    config: CustomButtonConfig;

    text: string;

    url: string;
}

export class PostDataContainer {
    // ? Colour of the spine (e.g. "#FFFFFF")
    backgroundColour: string;

    // ? Post title (e.g. "Some cool title lol")
    postTitle: string;

    // ? Post description (e.g. "Here is a description lol")
    postDescription: string;

    // ? Post date (e.g. "27 / 06 / 2022")
    postDate: string;

    // ? Post type (e.g. "Chaos Theory")
    postType: string;

    // ? Image  (e.g. "https://path/to/image.png")
    image1Url?: string | undefined;
    image2Url?: string | undefined;

    // ? Video (e.g. "https://yout.be/nyancate.com")
    videoURL?: string | undefined;

    mediaType?: MediaType | undefined;

    // ? Links to github or gcs
    links: LinkContainer[];
    
}