import { 
  Component, 
  Input, 
  OnInit, 
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';

import { MatRipple } from '@angular/material/core';
import { delay } from 'rxjs/operators';

import { 
  CustomButtonConfig,
  CustomButtonType,
  CustomButtonClickEvent
} from 'src/app/data_structures/specific/custom-button/custom-button.ds';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent implements OnInit {

  @Input() config: CustomButtonConfig;
  @Input() inputText: string;

  @Output() clickEvent = new EventEmitter<CustomButtonClickEvent>();

  @ViewChild(MatRipple) ripple: MatRipple;

  constructor() {}

  ngOnInit(): void {
    // Set the button text
    this.buttonText = this.inputText;

    if(this.config.regularButtonIconEnable) {
      this.buttonIconEnable = true;
      this.buttonIcon = this.config.icon;
    }

    this.isToggle = this.config.toggle;
    
    this.toggleClass = this.config.toggleClass;
    // Determine what type of button this is
    if(this.config.buttonType == CustomButtonType.REGULAR_BUTTON) {
      this.isRegularButton = true;

    } else if(this.config.buttonType == CustomButtonType.ICON_BUTTON) {
      this.isIconButton = true;
      this.transformFactor = this.config.transform;

    } else if(this.config.buttonType == CustomButtonType.TOOLBAR_BUTTON) {
      this.isToolbarButton = true;
      this.transformFactor = this.config.transform;

    } else if(this.config.buttonType == CustomButtonType.ICON_BUTTON_W_BG) {
      this.isIconButtonWBackground = true;
      this.transformFactor = this.config.transform;
    }

    if(this.config.isDisabled) {
      this.pointerEvents = 'none';
    }


    this.buttonHeight = this.config.height.toString() + 'px';
    this.buttonWidth = this.config.width.toString() + 'px';
  }

  // * PUBLIC METHOD -----------------------------------------------------------
  /**
   * Disables the button from responding to pointer events
   */
  public disable_button() {
    console.log("disabling button");
    this.pointerEvents = 'none';
  }

  /**
   * Enables the button, allowing it to respond to pointer events
   */
  public enable_button() {
    this.pointerEvents = 'auto';
  }
  
  public button_click(type: string) {
    if(this.isToggle) {
      if(this.hasClicked) {
        this.hasClicked = false;
      } else {
        this.hasClicked = true;
      }
    }

    switch(type) {
      case 'enter':
        if(!this.debounceActive) {
          this.debouncer();
          this.clickEvent.emit(CustomButtonClickEvent.KEYBOARD_ENTER);
        }
        // Only need to launch ripple when hitting 'enter'
        if(this.isRegularButton || this.isIconButtonWBackground) {
          this.ripple.launch({
            centered: true
          });
        }
        break;
      case 'mouse':
        if(!this.debounceActive) {
          this.clickEvent.emit(CustomButtonClickEvent.MOUSE_CLICK);
        }
        break;
      default:
        console.error("Unknown custom button click event");
    }
  }

  /**
   * Helper method that sets the state of the button
   * @param newState The new state of the button; true == ON, false == OFF
   */
  public set_has_clicked(newState: boolean) {
    this.hasClicked = newState;
  }

  /**
   * @returns the state of the 'hasClicked' variable.
   */
  public get_has_clicked() {
    return this.hasClicked;
  }

  /**
   * 
   * @returns The text of the button
   */
  public get_button_text() {
    return this.buttonText;
  }

  public set_button_text(newButtonText: string) {
    this.buttonText = newButtonText;
  }

  public set_transform_factor(newTransformFactor: string) {
    this.transformFactor = newTransformFactor;
  }

  // * PUBLIC VARIABLES --------------------------------------------------------
  public buttonText: string = 'placeholder';

  // ? Optional icon for the REGULAR_BUTTON mode (don't get confused with
  // ? ICON_BUTTON)
  public buttonIcon: string = "";
  public buttonIconEnable: boolean = false;

  // ? Is toggle enabled?
  public isToggle: boolean = false;

  // ? Current state of the button
  public hasClicked: boolean = false;

  public toggleClass: string = 'button-flat-colour-on'

  public buttonHeight: string = '36px';

  public buttonWidth: string = '100px';

  // ? Button types
  public isRegularButton: boolean = false;
  public isIconButton: boolean = false;
  public isToolbarButton: boolean = false;
  public isIconButtonWBackground: boolean = false;

  public transformFactor: string = "scale(1,1)";

  // ? Tooltip delay
  public matTooltipDelay: number = 250;

  // ? Button disabled
  public pointerEvents: string = "auto";

  // * PRIVATE METHODS ---------------------------------------------------------
  
  /**
   * Keyboards are spring loaded devices, hence they tend to 'bounce' a little
   * bit after each keystroke.
   * 
   * Hence this method will prevent any bouncing by preventing repeated emission
   * of events.
   */
  private async debouncer() {
    this.debounceActive = true;
    await delay(250);
    this.debounceActive = false;
  }

  // * PRIVATE VARIABLES -------------------------------------------------------
  private debounceActive: boolean = false;

}

