export enum CustomButtonType {
    // ? Regular flat button with text + optional icon
    REGULAR_BUTTON = 1,

    // ? Button with only an icon in the middle
    ICON_BUTTON,

    // ? Specific button for the toolbar at the top of the poge
    TOOLBAR_BUTTON,

    // ? Same as the ICON_BUTTON, but with a background
    ICON_BUTTON_W_BG
}

export enum CustomButtonClickEvent {
    KEYBOARD_ENTER = 1,
    MOUSE_CLICK = 2
}

export class CustomButtonConfig {
    // ? How wide do we want the button to be?
    width: number; 

    // ? How tall do we want the button to be?
    height: number;

    // ? When we click the button, do we want it to toggle?
    toggle: boolean;

    // ? When we click the button, what mixin class do we want to use?
    toggleClass: string;

    // ? The type of button this is
    buttonType: CustomButtonType;

    // ? SPECIFIC TO ICON_BUTTON, add a transform string if you dare :3
    transform: string | undefined; 

    // ? Optional icon for the REGULAR_BUTTON mode
    icon: string | undefined;
    regularButtonIconEnable: boolean;

    // ? Option disable button param
    isDisabled: boolean | undefined;
}
