import { Injectable } from '@angular/core';

import { Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { CookieService } from 'ngx-cookie';

import {
  OverlayContainer
} from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class ThemeTrackerService {

  constructor(private cookieService: CookieService,
      private overlayContainer: OverlayContainer,
      @Inject(PLATFORM_ID) platformId) {
    this.isBrowser = isPlatformBrowser(platformId);

    if(this.isBrowser) {
      const currentTheme = this.get_theme();
      if(currentTheme != undefined) {
        this.init_overlay_theme(currentTheme);
      }
    }
  }

  public set_theme(theme: string) {
    this.cookieService.put('theme', theme);
    this.init_overlay_theme(theme);
  }

  public get_theme() {
    return this.cookieService.get('theme');
  }

  private init_overlay_theme(theme: string) {
    if(theme == 'dark') {
      this.overlayContainer.getContainerElement().classList.add('dark-theme-mode');
    } else {
      this.overlayContainer.getContainerElement().classList.remove('dark-theme-mode');
    }
  
  }

  private isBrowser: boolean = false;
}

