<div class="intro-window" *ngIf="showIntroWindow && isBrowser">
    <app-intro-window (introDone)="intro_done_handler()"></app-intro-window>
</div>

<!-- TOOLBAR -->
<span [ngClass]="{'dark-theme-mode': isDarkTheme}" *ngIf="isBrowser"
        #mainContainerRef>
    <mat-toolbar class="toolbar" *ngIf="showToolbar" 
            [style.background-color]="toolbarBackgroundColour">
        <!-- Left -->
        <div class="toolbar-container-left">
            
        </div>

        <!-- Middle -->
        <div class="toolbar-container-centre">
            <app-search-bar (searchBarOutput)="searchbar_output_handler($event)">
            </app-search-bar>
        </div>

        <!-- Right -->
        <div class="toolbar-container-right">
            
            <!-- Settings -->
            <span class="round-background-container">
                <app-custom-button 
                    [matMenuTriggerFor]="settingsMenu"
                    [inputText]="settingsButtonText" 
                    [config]='settingsButtonConfig' 
                    (clickEvent)='toolbar_button_handler("settings")'>
                </app-custom-button>
            </span>


            <mat-menu #settingsMenu>
                <!-- Theme toggle switch -->
                <button mat-menu-item (click)="toolbar_button_handler('toggle-dark-mode')">
                    <mat-icon>dark_mode</mat-icon>
                    <span>Toggle Dark Mode</span>
                </button>
                <!-- Contact details button -->
                <button mat-menu-item (click)="toolbar_button_handler('contact-details')">
                    <mat-icon>alternate_email</mat-icon>
                    <span>Contact Details</span>
                </button>
              </mat-menu>        
        </div>
    </mat-toolbar>

    <!-- Padding -->
    <div class="toolbar-background-filler"
            [style.height]="toolbarBackgroundFillerHeight">
    </div>

    <!-- Main container where all the magic happens -->
    <div class="main-container background-colour"
            [style.height]="mainBackgroundHeight">
        <div class="test2" (scroll)="handle_scroll_change($event)">
            <app-homepage #homepageRef>
            </app-homepage>
        </div>
        
    </div>

    <div class="test">
        <app-wave-function-generator #wavegenRef 
            (colourChangeEvent)="colour_change_handler($event)">
        </app-wave-function-generator>
    </div>
</span>


