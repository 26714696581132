import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralHelperService {

  constructor() { }

  public clone_object(object: Object) {
    return JSON.parse(JSON.stringify(object));
  }
}
