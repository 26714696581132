// ANGULAR ---------------------------------------------------------------------

import { 
  Component, 
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';

// THIRD PARTY -----------------------------------------------------------------

// HELPERS ---------------------------------------------------------------------

import {
  StableRingHelperService
} from 'src/app/services/stable_ring_helper/stable-ring-helper.service';

import {
  ResizeHelperService
} from 'src/app/services/resize_helper/resize-helper.service';

// INTERFACES ------------------------------------------------------------------

import {
  WindowResize
} from 'src/app/services/resize_helper/resize-helper.interface';

// APIS ------------------------------------------------------------------------

// COMPONENTS ------------------------------------------------------------------

// ENUMS -----------------------------------------------------------------------

// DATA STRUCTURES -------------------------------------------------------------

import { 
  WindowStates 
} from 'src/app/data_structures/shared/window-states/window-states.ds';


@Component({
  selector: 'app-intro-window',
  templateUrl: './intro-window.component.html',
  styleUrls: ['./intro-window.component.scss']
})
export class IntroWindowComponent implements AfterViewInit, WindowResize {

  @ViewChild('canvasRef') canvasRef: ElementRef<HTMLCanvasElement>;
  @ViewChild('bgCanvasRef') bgCanvasRef: ElementRef<HTMLCanvasElement>;

  @Output() introDone = new EventEmitter<void>();

  constructor(private stableRing: StableRingHelperService,
      private windowResizeHelper: ResizeHelperService,
      private cd: ChangeDetectorRef) { 
    // Initialise the current window size elements
    this.windowResizeHelper.get_screen_width_observable().subscribe(result => {
        this.window_state_change(result);
    });

    // Get the initial window state
    this.window_state_change(
        this.windowResizeHelper.get_current_screen_state());
  }

  ngAfterViewInit(): void {
    this.context = this.canvasRef.nativeElement.getContext('2d');
    this.bgContext = this.bgCanvasRef.nativeElement.getContext('2d');
    this.init_spiral_canvas();
    this.init_starting_position();
    this.init_background_canvas();
    this.cd.detectChanges();
  }

  // * PUBLIC METHODS ----------------------------------------------------------

  public go_button() {
    this.hasPressedGo = true;
    this.showLogo = false;
  }

  public window_state_change(newState: WindowStates) {
    switch(newState) {
      case WindowStates.XSMALL:
        // Resize elements to be XSMALL (x < 600)
        this.logoFontSize = '35px';
        break;
      case WindowStates.SMALL:
        // Resize elements to be SMALL (600 <= x <1024)
        this.logoFontSize = '50px';
        break;
      case WindowStates.MEDIUM:
        // Resize elements to be MEDIUM (1024 <= x < 1440)
        this.logoFontSize = '64px';
        break;
      case WindowStates.LARGE:
        // Resize elements to be LARGE (1440 <= x < 1920)
        this.logoFontSize = '64px';
        break;
      case WindowStates.XLARGE:
        // Resize elements to be XLARGE (1920 <= x)
        this.logoFontSize = '64px';
        break;
      case WindowStates.SERVER:
        
        break;
      default:
        console.error("Unknown windows state: " + newState);
    }
  }


  // * PUBLIC VARIABLES --------------------------------------------------------

  public context: CanvasRenderingContext2D;
  public bgContext: CanvasRenderingContext2D;
  public tickRef: any;

  public showLogo: boolean = true;

  public logoFontSize: string = '64px';

  public logoText: string = 'Welcome';

  // * PRIVATE METHODS ---------------------------------------------------------

  private init_spiral_canvas() {
    this.canvasRef.nativeElement.width = outerWidth;
    this.canvasRef.nativeElement.height = outerHeight;

    this.animate();

    // Start timestep function
    this.tick();

    // Start the logo text timer
    this.logo_text_change_timer()
    
  }

  private init_background_canvas() {
    this.bgCanvasRef.nativeElement.width = outerWidth;
    this.bgCanvasRef.nativeElement.height = outerHeight;

    this.bg_animate();
    

  }

  private init_starting_position() {
    const denom = 6;
    const num = 5;

    this.x1 = innerWidth/denom;
    this.y1 = innerHeight/denom;

    this.x2 = num*innerWidth/denom;
    this.y2 = num*innerHeight/denom;

    this.offsetX = -innerWidth/2;
    this.offsetY = -innerHeight/2;
  }

  private tick() {
    // Do stuff
    if(this.counter < 10e10) {
      this.animate();
      this.bg_animate();
      
      if(this.hasPressedGo) {
        this.counter += 3;
      }
    }

    if(this.counter > 50) {
      this.introDone.emit();
    } else {
      let _this = this;
      setTimeout(function() {
          
          _this.tick();

      }, this.tickTimeMs);
    }
  }

  /**
   * Changes the text every 5 seconds
   */
  private logo_text_change_timer() {
    let _this = this;

    if(this.logoTextIndicator) {
      this.logoText = 'Welcome';
      this.logoTextIndicator = false;
    } else {
      this.logoText = 'CLICK ME';
      this.logoTextIndicator = true;
    }

    setTimeout(function() {
      _this.logo_text_change_timer()
    }, 2500);
  }

  private bg_animate() {
    this.bgCanvasRef.nativeElement.width = innerWidth;
    this.bgCanvasRef.nativeElement.height = innerHeight;

    this.bgContext.fillStyle = '#f44336';

    this.bgContext.fillRect(0, 0, this.bgCanvasRef.nativeElement.width, 
        this.bgCanvasRef.nativeElement.height);
    
    
    this.bgContext.fill();

    if(this.hasPressedGo) {
      this.clear_circle(innerWidth/2, innerHeight/2, this.counter*this.counter,
        this.bgContext);
    }
  }

  private clear_circle(x: number, y: number, radius: number, 
      context: CanvasRenderingContext2D) {
    context.beginPath();
    context.arc(x, y, radius, 0, 2 * Math.PI, false);
    context.clip();
    context.clearRect(0, 0, innerWidth, innerHeight);
  }

  private animate() {
    this.offsetX = -innerWidth/2;
    this.offsetY = -innerHeight/2;
    this.canvasRef.nativeElement.width = innerWidth;
    this.canvasRef.nativeElement.height = innerHeight;

    this.context.lineWidth = 10;
    this.context.strokeStyle = 'white';

    // Clear the path
    this.context.clearRect(0,0,this.canvasRef.nativeElement.width, 
      this.canvasRef.nativeElement.height);
    
    // ? x1, y1
    this.context.beginPath();

    this.context.moveTo(this.x1, this.y1);
    
    let prevX = this.x1;
    let prevY = this.y1;
    
    
    for(let i = 0; i < this.tailLength; i++) {
      const newState = this.update_state(prevX, prevY);
      const newX = newState[0];
      const newY = newState[1];
      
      if(i == 0) {
        this.x1 = newX;
        this.y1 = newY;
      }

      this.context.lineTo(newX, newY);

      // Update for the next iteration.
      prevX = newX;
      prevY = newY;
    }

    this.context.stroke();


    // ? x2, y2
    this.context.beginPath();

    this.context.moveTo(this.x2, this.y2);
    
    prevX = this.x2;
    prevY = this.y2;

    
    for(let i = 0; i < this.tailLength; i++) {
      const newState = this.update_state(prevX, prevY);
      const newX = newState[0];
      const newY = newState[1];
      
      if(i == 0) {
        this.x2 = newX;
        this.y2 = newY;
      }

      this.context.lineTo(newX, newY);

      // Update for the next iteration.
      prevX = newX;
      prevY = newY;
    }

    this.context.stroke();

    if(this.hasPressedGo) {
      this.clear_circle(innerWidth/2, innerHeight/2, this.counter*this.counter,
        this.context);
    }
  }

  private update_state(x: number, y: number) {
    let tempX = this.stableRing.dx_offset(x, y, this.lambda, this.offsetX, 
        this.offsetY);
    
    // Add the brakes if necessary
    if(tempX > this.retarderLimit) {
      tempX = this.retarderLimit
    } else if(tempX < -this.retarderLimit) {
      tempX = -this.retarderLimit
    }
    x += tempX * this.stepSize

    let tempY = this.stableRing.dy_offset(x, y, this.lambda, this.offsetX, 
        this.offsetY);

    if(tempY > this.retarderLimit) {
      tempY = this.retarderLimit
    } else if(tempY < -this.retarderLimit) {
      tempY = -this.retarderLimit
    }
    y += tempY * this.stepSize

    return [x,y];
}

  // * PRIVATE VARIABLES -------------------------------------------------------

  // ? Top left corner
  private x1: number;
  private y1: number;

  // ? Bottom Right corner
  private x2: number;
  private y2: number;

  private stepSize: number = 10*Math.pow(10, -8);
  private theta: number;
  private lambda: number = 2000;

  private offsetX: number;
  private offsetY: number;

  private tickTimeMs = 20;

  private tailLength = 80;

  private retarderLimit = 1000000000;

  private hasPressedGo: boolean = false;

  private counter = 0;

  private logoTextIndicator: boolean = true;

}
