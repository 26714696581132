// ANGULAR ---------------------------------------------------------------------

import { 
  Component, 
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList
} from '@angular/core';

// THIRD PARTY -----------------------------------------------------------------

const cinterp = require('color-interpolate');

// HELPERS ---------------------------------------------------------------------

// INTERFACES ------------------------------------------------------------------

// APIS ------------------------------------------------------------------------

// COMPONENTS ------------------------------------------------------------------

import {
    ProjectPostComponent
} from 'src/app/subcomponents/project-post/project-post.component';

// ENUMS -----------------------------------------------------------------------

// DATA STRUCTURES -------------------------------------------------------------

// DATA ------------------------------------------------------------------------

import {
    projectData
} from 'src/app/pages/homepage/projects';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements AfterViewInit {

    @ViewChild('mainContainerRef') mainContainerRef: ElementRef;

    @ViewChildren('marker') markerRef: QueryList<ProjectPostComponent>;

    // ? Output events
    @Output() scrollPositionChange = new EventEmitter<number>();

    constructor(private cd: ChangeDetectorRef) { 

        for(let i = 0; i < projectData.length; i++) {
            const position = i / projectData.length;
            const colour = this.colourInterp(position)
            projectData[i].backgroundColour = colour;
        }
    }
    
    ngAfterViewInit(): void {
        let _this = this;
        window.addEventListener("scroll", () => {
            console.log("Scroll");
        })

        this.cd.detectChanges();
    }

    // * PUBLIC METHODS --------------------------------------------------------

    public scroll_to_project(projectTitle: string) {
        console.log(projectTitle);
        const targetProject = this.markerRef.toArray().filter(
                r => r.postTitle === projectTitle)[0];
        
        // Turn off the last highlight
        if(this.lastTargetProject != undefined) {
            this.lastTargetProject.set_spine_highlight(false);
        }

        // Turn on the current highlight
        targetProject.set_spine_highlight(true);

        // Scroll the target project into view
        targetProject.get_native_element().scrollIntoView({behavior: 'smooth'})
        
        // Save the last target project
        this.lastTargetProject = targetProject;

    }

    // * PUBLIC VARIABLES ------------------------------------------------------
    public toolbarBackgroundColour: string;
    public mainBackgroundHeight = 'calc(100% - 64px)';
    public toolbarBackgroundFillerHeight = '64px';

    public posts = projectData;

    // * PRIVATE METHODS -------------------------------------------------------

    /**
     * Computes the current scroll position as a percentage (0 to 100)
     * @returns 
     */
    public handle_scroll_change($event) {
        var h = document.documentElement, 
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';

        // Emit an event to the parent.
        const scrollP = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 
                100;
        console.log("Scroll");
        this.scrollPositionChange.emit(scrollP);
    }

    // * PRIVATE VARIABLES -----------------------------------------------------
    
    private colourInterp = cinterp(['#f44336', '#ffc107', '#4caf50']);
    private lastTargetProject: ProjectPostComponent;

}
