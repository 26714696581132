<!-- Textarea container -->
<div *ngIf="showTextareaForm" class="input-form-container">
    <span [formGroup]="config.parentForm" 
            class="input-container input-field-colour">
            
        <mat-form-field appearance="standard" 
                color="accent"
                [ngStyle] = "{
                    'width' : config.formWidth
                }">
            <mat-label>{{config.formLabel}}</mat-label>
            <textarea matInput 
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    [cdkAutosizeMinRows]="minNumRows"
                    cdkAutosizeMaxRows="{{config.maxRows}}"
                    placeholder="{{config.placeholderDescription}}"
                    formControlName="{{config.formControlName}}"
                    class="summary-text-area"
                    #textareaInput
                    (input)="text_limit_update('summary')"
                    (keyup)="check_summary_height()"
                    maxlength="{{config.maxCharacters}}"
                    ></textarea>
        </mat-form-field>
    </span>
    <span class="summary-limits" *ngIf="config.showCount">
        <div class="summary-char-limit">Chars remaining: {{summaryCharRemaining}}</div>
        <div class="summary-char-limit">Rows remaining: {{summaryRowsRemaining}}</div>
    </span>
</div>

<!-- Input Container -->
<div *ngIf="showInputForm" class="input-form-container">
    <!-- Title -->
    <span [formGroup]="config.parentForm" 
            class="input-container input-field-colour">
        <mat-form-field appearance="standard" 
                color="accent"
                [ngStyle] = "{
                    'width' : config.formWidth
                }">
            <mat-label>{{config.formLabel}}</mat-label>
            <input matInput 
                    autocomplete="off"
                    placeholder="{{config.placeholderDescription}}"
                    formControlName="{{config.formControlName}}"
                    (input)="text_limit_update('title')"
                    maxlength="{{config.maxCharacters}}"
                    type="{{config.inputType}}">
        </mat-form-field>
    </span>
    <span class="title-limits" *ngIf="config.showCount">
        <div class="title-char-limit input-field-subtext-color">
            Chars remaining: {{titleCharRemaining}}
        </div>
    </span>
</div>

<!-- Datepicker Container -->
<div *ngIf="showDatepickerForm" class="input-form-container">
    <div class="input-container input-field-colour" 
            [formGroup]="config.parentForm">
        <mat-form-field appearance="standard" 
                [ngStyle] = "{
                    'width' : config.formWidth
                }"
                color="accent">
            <mat-label>{{config.formLabel}}</mat-label>
            <input matInput placeholder="{{config.placeholderDescription}}" 
                    [matDatepicker]="picker"
                    formControlName="{{config.formControlName}}">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>
    <span class="title-limits" *ngIf="config.showCount">
        <div class="title-char-limit">
            Click the icon on the right
        </div>
    </span>
</div>

