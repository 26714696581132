import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// * Forms stuff ---------------------------------------------------------------

import { ReactiveFormsModule } from '@angular/forms';

// * Material Imports ----------------------------------------------------------
import {
  AngularMaterialModule
} from './material-module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


// * Third party modules -------------------------------------------------------
import { CookieModule } from 'ngx-cookie';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WaveFunctionGeneratorComponent } from './subcomponents/wave-function-generator/wave-function-generator.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { CustomButtonComponent } from './subcomponents/custom-button/custom-button/custom-button.component';
import { ProjectPostComponent } from './subcomponents/project-post/project-post.component';
import { IntroWindowComponent } from './subcomponents/intro-window/intro-window.component';
import { ContactDetailsComponent } from './dialog_menus/contact-details/contact-details.component';
import { TextInputFieldComponent } from './subcomponents/text-input-field/text-input-field.component';
import { SearchBarComponent } from './subcomponents/search-bar/search-bar.component';

@NgModule({
  declarations: [
    AppComponent,
    WaveFunctionGeneratorComponent,
    HomepageComponent,
    CustomButtonComponent,
    ProjectPostComponent,
    IntroWindowComponent,
    ContactDetailsComponent,
    TextInputFieldComponent,
    SearchBarComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ReactiveFormsModule,
    AppRoutingModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    CookieModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
