export enum DialogSizeMode {
    SMALL = 1,
    BIG
}

export class ContactDetailsDialogData {
    name: string;

    email: string;

    phone: string;

    githubURL: string;

    linkedinURL: string;

    stackoverflowURL: string;

    mathstackexchangeURL: string;

    mode: DialogSizeMode;
}