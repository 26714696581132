/**
 * Used to quantify the different window states across the platform.
 * The intention of this is to standardise the resizing of elements '
 * ! NOTE
 * ? px in css is the same thing as 'dp' (density independent pixels), so you
 * ? DO NOT need to worry about supporting each device.
 */
 export enum WindowStates {
    XSMALL = 1,   // 0px - 599px 
    SMALL = 2,    // 600px - 1023px 
    MEDIUM = 3,   // 1024px - 1439px
    LARGE = 4,    // 1440px - 1919px
    XLARGE = 5,   // 1920px <
    SERVER = 6    // Code is running on the server right now
  }
