import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StableRingHelperService {

    constructor() { }

    // * PUBLIC METHODS --------------------------------------------------------

    public convert_x_y_to_r(x: number, y: number) {
        return Math.sqrt(Math.pow(x,2) + Math.pow(y, 2));
    }

    public convert_x_y_to_theta(x: number, y: number) {
        return Math.atan2(y,x);
    }

    /**
     * 
     * @param x 
     * @param y 
     * @param lamb 
     * @returns 
     */
    public dx(x: number, y: number, lamb: number) {
        const r2 = this.compute_r2(x, y);
        const xdot = (r2 * lamb - Math.pow(r2,2) - this.sv*y*r2/x)/
                (x + Math.pow(y,2)/x);
        return xdot;
    }

    /**
     * 
     * @param x 
     * @param y 
     * @param lamb 
     * @returns 
     */
    public dy(x: number, y: number, lamb: number) {
        const r2 = this.compute_r2(x, y);
        const xdot = (r2 * lamb - Math.pow(r2,2) - this.sv*y*r2/x)/
                (x + Math.pow(y,2)/x);
        const ydot = (this.sv*r2 + y*xdot) / x;
        return ydot;
    }

    /**
     * 
     * @param x 
     * @param y 
     * @param lamb 
     * @returns 
     */
     public dx_offset(x: number, y: number, lamb: number, offsetX: number, 
            offsetY: number) {

        x = x + offsetX;
        y = y + offsetY;

        const r2 = this.compute_r2(x, y);
        const xdot = (r2 * lamb - Math.pow(r2,2) - this.svOffset*y*r2/x)/
                (x + Math.pow(y,2)/x);
        return xdot;
    }

    /**
     * 
     * @param x 
     * @param y 
     * @param lamb 
     * @returns 
     */
    public dy_offset(x: number, y: number, lamb: number, offsetX: number, 
            offsetY: number) {
                x = x + offsetX;
                y = y + offsetY;

        const r2 = this.compute_r2(x, y);
        const xdot = (r2 * lamb - Math.pow(r2,2) - this.svOffset*y*r2/x)/
                (x + Math.pow(y,2)/x);
        const ydot = (this.svOffset*r2 + y*xdot) / x;
        return ydot;
    }

    public dx_offset_reverse(x: number, y: number, lamb: number, offsetX: number, 
            offsetY: number) {
        
    }
    

    // * PRIVATE METHODS -------------------------------------------------------

    private compute_r2(x: number, y: number) {
        return Math.pow(x,2) + Math.pow(y,2);
    }

    // Spin velocity (use the change theta dot)
    private sv: number = 10;
    private svOffset: number = 1500000;

    private rv: number = 0.5;

}
