// ANGULAR ---------------------------------------------------------------------

import { 
  Component, 
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';

import { 
  DomSanitizer, 
  SafeUrl 
} from '@angular/platform-browser';

// THIRD PARTY -----------------------------------------------------------------

// HELPERS ---------------------------------------------------------------------

import {
  GeneralHelperService
} from 'src/app/services/general_helper/general-helper.service';

import {
  ResizeHelperService
} from 'src/app/services/resize_helper/resize-helper.service';

// INTERFACES ------------------------------------------------------------------

import {
  WindowResize
} from 'src/app/services/resize_helper/resize-helper.interface';

// APIS ------------------------------------------------------------------------

// COMPONENTS ------------------------------------------------------------------

// ENUMS -----------------------------------------------------------------------

// DATA STRUCTURES -------------------------------------------------------------

import {
  CustomButtonConfig,
  CustomButtonType
} from 'src/app/data_structures/specific/custom-button/custom-button.ds';

import {
  PostDataContainer,
  CustomButtonContainer,
  LinkContainer,
  LinkButtonType,
  MediaType
} from 'src/app/data_structures/shared/project_post/project_post.ds';

import { 
  WindowStates 
} from 'src/app/data_structures/shared/window-states/window-states.ds';

@Component({
  selector: 'app-project-post',
  templateUrl: './project-post.component.html',
  styleUrls: ['./project-post.component.scss']
})
export class ProjectPostComponent implements OnInit, AfterViewInit, WindowResize {

  @Input() inputData: PostDataContainer;

  @ViewChild('mainContainerRef') mainContainerRef: ElementRef;
  @ViewChild('contentContainerRef') contentContainerRef: ElementRef;
  

  constructor(private cd: ChangeDetectorRef,
      public element: ElementRef,
      private windowResizeHelper: ResizeHelperService,
      private sanitizer: DomSanitizer,
      private generalHelpers: GeneralHelperService) { 
    this.windowResizeHelper.get_screen_width_observable().subscribe(result => {
        this.window_state_change(result);
    });

    // Get the initial window state
    this.window_state_change(
        this.windowResizeHelper.get_current_screen_state());
  }

  ngOnInit(): void {
    if(this.inputData != null) {
      this.input_data_init();
    }
  }

  ngAfterViewInit(): void {
      this.defaultHeight = this.mainContainerRef.nativeElement.offsetHeight;
      this.resizeObserver.observe(this.mainContainerRef.nativeElement);
      this.cd.detectChanges();
  }

  // * PUBLIC METHODS ----------------------------------------------------------

  public get_native_element() {
    return this.element.nativeElement;
  }

  public set_spine_highlight(state: boolean) {
    if(state) {
      // Turn the spine blue
      this.backgroundColour = '#3498db';
    } else {
      // Turn the spine back to its original colour
      this.backgroundColour = this.spineOriginalColour;
    }
  }

  public window_resize_event_handler($event) {
    this.spine_update_routine(this.contentContainerRef.nativeElement
        .offsetHeight);
  }

  public window_state_change(newState: WindowStates) {
    this.currentWindowState = newState;
    switch(newState) {
      case WindowStates.XSMALL:
        // Resize elements to be XSMALL (x < 600)
        this.postWidth = '90vw';
        this.spineWidth= '30px';
        this.buttonWidth = 108;
        break;
      case WindowStates.SMALL:
        // Resize elements to be SMALL (600 <= x <1024)
        this.postWidth = '600px';
        this.spineWidth= '40px';
        this.buttonWidth = 130;
        break;
      case WindowStates.MEDIUM:
        // Resize elements to be MEDIUM (1024 <= x < 1440)
        this.postWidth = '600px';
        this.buttonWidth = 130;
        break;
      case WindowStates.LARGE:
        // Resize elements to be LARGE (1440 <= x < 1920)
        this.postWidth = '600px';
        this.buttonWidth = 130;
        break;
      case WindowStates.XLARGE:
        // Resize elements to be XLARGE (1920 <= x)
        this.postWidth = '600px';
        this.buttonWidth = 130;
        
        break;
      case WindowStates.SERVER:
        
        break;
      default:
        console.error("Unknown windows state: " + newState);
    }
  }

  /**
   * Opens a new tab (or window depending on your tab preference) with the 
   *  specified link.
   * @param clickedId 
   */
  public button_handler(clickedId: string) {
    window.open(this.buttons.find(e => e.id == clickedId).url);
  }

  // * PUBLIC VARIABLES --------------------------------------------------------

  public spineHeight: string = '0px';
  public showSpine: boolean = false;
  public postVisibility: string = 'hidden';
  public backgroundColour: string = 'red';

  // ? Resize Widths
  public postWidth: string = '500px';
  public spineWidth: string = '40px';
  public imageWidth: string = '272px';

  public buttonWidth: number = 130;

  public postTitle: string = 'Default title';
  public postDescription: string = 'This is a demo description';
  public postDate: string = '27 / 06 / 2021';
  public postType: string = "Chaos Theory";

  // ? Image URLS
  public image1Url: string = 'https://i1.sndcdn.com/artworks-000433519539-e2n6ks-t500x500.jpg';
  public image2Url: string = 'https://i1.sndcdn.com/artworks-000433519539-e2n6ks-t500x500.jpg';

  // ? Video URL
  public youtubeURL: SafeUrl = '';

  // ? Media type flags
  public isVideo: boolean = false;
  public isImage: boolean = false;

  public buttons: CustomButtonContainer[] = [];

  public resizeObserver = new ResizeObserver(entries => {
    entries.forEach(entry => {
      this.spine_update_routine(entry.contentRect.height);
    })
  })

  // * PRIVATE METHODS ---------------------------------------------------------

  private input_data_init() {
    this.spineOriginalColour = this.inputData.backgroundColour;
    this.backgroundColour = this.inputData.backgroundColour;
    this.postTitle = this.inputData.postTitle;
    this.postDescription = this.inputData.postDescription;
    this.postDate = this.inputData.postDate;
    this.postType = this.inputData.postType;
    
    switch(this.inputData.mediaType) {
        case MediaType.VIDEO:
            this.youtubeURL = this.sanitizer.
            bypassSecurityTrustResourceUrl(
              this.inputData.videoURL)
            this.isVideo = true;
            this.isImage = false;
            break;
        case MediaType.IMAGE:
            this.image1Url = this.inputData.image1Url;
            this.image2Url = this.inputData.image2Url;
            this.isVideo = false;
            this.isImage = true;
            break;
        default:
            console.error("Unknown media type: " + this.inputData.mediaType);
    }

    this.button_init();
  }

  private button_init() {
    const commonToolbarConfigTemplate: CustomButtonConfig = {
      buttonType: CustomButtonType.REGULAR_BUTTON,
      height: 40,
      icon: '',
      regularButtonIconEnable: true,
      transform: 'scale(1, 1)',
      toggle: false,
      toggleClass: 'toolbar-button-colour-on',
      width: this.buttonWidth,
      isDisabled: false
    }

    for(let i = 0; i < this.inputData.links.length; i++) {
      const cloned = this.generalHelpers.clone_object(
          commonToolbarConfigTemplate);

      const currentLink = this.inputData.links[i];

      switch(currentLink.linkType) {
        case LinkButtonType.GITHUB:
          cloned.icon = 'code'
          break;
        case LinkButtonType.PDF:
          cloned.icon = 'picture_as_pdf'
          break;
        case LinkButtonType.DOWNLOAD:
          cloned.icon = 'cloud_download'
          break;
        default:
          console.error("Unknown link button type: " + currentLink.linkType);
      }

      // Push config to button
      this.buttons.push(
        {
          id: i.toString(),
          config: cloned,
          text: currentLink.linkText,
          url: currentLink.link
        }
      )
    }
  }

  private spine_update_routine(height: number) {
    this.spineHeight = height.toString() + 'px';
    this.postVisibility = 'visible';
    this.cd.detectChanges();
  }

  // * PRIVATE VARIABLES -------------------------------------------------------

  private defaultHeight: number;
  private currentWindowState: WindowStates;
  private spineOriginalColour: string;
}
