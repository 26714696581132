<div class="main-container">
    <!-- Logo -->
    <div class="logo-text-container" (click)="go_button()" *ngIf="showLogo"
            [style.font-size]="logoFontSize">
        {{logoText}}
    </div>

    <!-- Trajectory -->
    <div class="trajectory-container">
        <canvas class="canvas-class" #canvasRef></canvas>
    </div>

    <!-- Hole -->
    <div class="hole-container">
        <canvas class="canvas-background" #bgCanvasRef></canvas>
    </div>
</div>
